$nav-width: 70px;
$nav-expanded-width: 214px;

.layout-page {
  position: relative;
  top: 0;
  width: 100vw;
  min-height: 100vh;

  .main {
    &-app {
      &-layout {
        display: flex;
        z-index: 8;
        position: relative;
        height: 100%;
      }
    }

    &-nav {
      @include respond(as) {
        display: flex;
      }
      z-index: 25;
      position: fixed;
      top: 0;
      left: 0;
      display: none;
      justify-content: space-between;
      padding: 0 15px;
      width: 100%;
      height: 50px;
      background: color(purple);

      i {
        align-self: center;
        color: white;
      }

      &__logo {
        margin: auto 0;
        height: max-content;

        img {
          height: 30px;
        }
      }
    }

    &-layout {
      @include respond(as) {
        padding: 70px 10px 0;
        width: 100%;
      }

      position: relative;
      float: right;
      width: 100%; //$nav-width
      height: 100vh;
      padding: 3rem 1rem 3rem 8rem;
      // padding-top: 30px;
      // padding-left: 70px;

      overflow: auto;

      -webkit-transition-property: top, bottom;
      transition-property: top, bottom;
      -webkit-transition-duration: 0.2s, 0.2s;
      transition-duration: 0.2s, 0.2s;
      -webkit-transition-timing-function: linear, linear;
      transition-timing-function: linear, linear;
      -webkit-overflow-scrolling: touch;

      min-height: 100vh;

      -webkit-transition: all 100ms linear;
      -moz-transition: all 100ms linear;
      -o-transition: all 100ms linear;
      transition: all 100ms linear;

      &.sidepanel-expanded {
        padding-left: 214px;

        @include respond(small) {
          padding-left: 0;
        }
      }
    }
  }

  .side {
    &-layout {
      background-color: theme(primary);
      position: absolute;
      bottom: 0;
      left: 0;
      overflow: auto;

      max-height: 100%;
      height: 100%;
      width: $nav-width;
      padding-top: 24px;

      display: block;
      z-index: 25;

      -webkit-transition: all 250ms ease-out;
      -moz-transition: all 250ms ease-out;
      -o-transition: all 250ms ease-out;
      transition: all 250ms ease-out;

      // @include respond(small) {
      //   right: 0 !important;
      //   left: auto;
      //   position: absolute;
      //   display: none;
      // }
    }

    &-mobile {
      left: -300px;
      padding-top: 50px;

      &-expanded {
        left: 0;
      }
    }

    &-expanded {
      width: $nav-expanded-width;
      overflow: visible;
      // overflow-y: auto;
      // @include respond(small) {
      //   left: 0;
      //   position: absolute;
      //   display: block;
      // }
    }
  }
}

@media print {
  .no-print {
    display: none !important;
  }
  .yes-print {
    display: initial !important;
  }
}
