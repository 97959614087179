.product-dropdown {
  &-holder {
    overflow-y: auto;
  }

  &-loader {
    position: absolute;
    left: 41%;
    height: 80px;
    background: red;

    .loader-block {
      min-height: unset;
    }
  }

  &-selector {
    display: flex;
  }

  &-selected {
    @include font-size(small);
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    div {
      display: flex;
      margin-right: 7px;
      margin-bottom: 8px;
      border-radius: 30px;
      padding: 3px 1rem;
      max-width: 85%;
      height: max-content;
      background: rgba(106, 105, 228, 0.2);
      color: rgb(51, 51, 51);

      span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: color(dark);
      }

      i {
        margin: auto 0;
        padding: 0 10px;
        font-size: 10px;
        color: var(--secondary-color);
      }
    }

    input {
      flex-grow: 1;
      margin-bottom: 7px;
      width: unset !important;
    }
  }

  .context-menu-body {
    margin: 12px 2.5%;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;
    width: 95%;
  }

  ul {
    position: relative;
    top: -5px;
    margin-bottom: 0;
    width: 100%;
    height: 100%;
    min-height: 80px;
    max-height: 230px;
    overflow-y: auto;
  }

  li {
    @include font-size(regular);
    margin-bottom: 0 !important;
    padding: 10px;

    &:hover {
      background: #deebfe;
    }
  }
}
