.frontdesk-context-menu {
  position: relative;

  &__body {
    z-index: 10;
    position: absolute;
    top: 2.5rem;
    right: 0.5rem;
    box-shadow: 0 4px 20px 0 rgba(21, 21, 25, 0.08);
    border-radius: 4px;
    padding: 0;
    min-width: 15rem;
    max-height: 0;
    overflow: hidden;
    background: color(white);
    list-style: none;
    color: color(context-menu-item);
  }

  .is-open {
    padding: 1rem;
    max-height: 25rem;
    overflow: auto;
  }

  .lower-child {
    bottom: 1rem;
  }

  &__text {
    @include font-size(x-small);
    cursor: pointer;
    text-align: left;

    &:hover {
      color: theme(secondary) !important;
    }

    &:not(:last-child) {
      margin-bottom: 8px;
    }

    &:last-child {
      margin-bottom: 1px;
    }
  }

  .iconmoon {
    padding: 0.5rem;
    cursor: pointer;

    &:hover {
      color: theme(primary) !important;
    }
  }
}

.frontdesk-table-location {
  box-sizing: border-box;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(67, 102, 118, 0.148353);
  border-radius: 7px;
  border: 1px solid rgba(67, 102, 118, 0.148353);
  padding-top: 7.5px;
  max-width: 13rem;
  background: #ffffff;
  cursor: pointer;

  .iconmoon {
    color: theme(primary);
  }
}
