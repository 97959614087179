.frontdesk-patients {
  .content-table {
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.002);
    border-radius: 0;
    border: 0;
    padding-top: 0;
    background-color: transparent !important;
  }

  &__registration {
    margin-bottom: 5rem;
    box-shadow: 0 3px 15px rgba(0, 0, 0, 0.12);
    border: 0;

    .header {
      border-top: 3px solid theme(primary);
    }

    .upload-logo {
      justify-content: flex-start;
      margin-bottom: 0;
      border: 0;
      padding: 0;
      min-height: inherit;
      background: transparent;
    }

    .settings-icon {
      color: theme(primary);
    }
  }

  .table {
    &-header {
      background-color: transparent !important;
    }

    &-body {
      padding: 4rem !important;

      tr {
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
        background-color: color(white);

        &:nth-child(1) {
          td {
            padding-top: 2rem !important;
          }
        }
      }
    }


    &-cell {
      border-bottom: 0 !important;
      vertical-align: middle;

      a {
        text-decoration: none;
        color: inherit;

        &:hover {
          color: theme(primary);
        }
      }

      &:first-child {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }

      &:last-child {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }

      &__avatar {
        border-radius: 50%;
        width: 45px;
        height: 45px;
        overflow: hidden;
      }

      &__avatar img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

    }
  }
}
