.daypickerinput {
  display: block;

  input {
    display: block;
    margin-bottom: 0;
    box-shadow: none;
    border-radius: 0.25rem;
    border: 0;
    padding: 0;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    background: transparent;
    line-height: 1.5;
    letter-spacing: 1px;
    font-size: 1.4rem;
    font-weight: 600;
    color: color(dark);
    transition: outline 0.2s;

    &::placeholder {
      color: rgba(color(dark), 0.4);
    }

    &:focus,
    &:active {
      outline: none;
      box-shadow: none;
      border: 0;
    }
  }
}

.date-input {
  position: absolute !important;
  top: 30px;
  right: 15px;
  color: color(grey);

  &__calendar {
    top: 15px;
    right: 15px;
  }
}

.timepicker {
  &--md {
    height: 30px;
    position: relative;
    top: -6px;
  }
}
