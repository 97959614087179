.profile-detail {
    border-bottom: 1px solid color(light-grey);
    padding: 0.5rem 0 1rem;

    &__label {
        margin-bottom: 0.5rem;
        letter-spacing: 0.5px;
        font-size: 1.2rem;
        color: color(grey);
    }

    &__info {
        margin-bottom: 0;
        padding-right: 2rem;
        font-size: 1.4rem;
        color: color(dark);
    }
}
