.supplier {
  &-view {
    &-formatter {
      margin-bottom: 39px;

      p {
        @include font-weight(medium);
        margin-bottom: 20px;
      }

      p:first-child {
        @include font-weight(regular);
        @include font-size(small);
        margin-bottom: 8px;
        color: color(grey);
      }

      p.not-assigned {
        @include font-weight(bold);
        color: color(dark);
      }
    }

    &-link {
      width: max-content;
      cursor: pointer;
      text-decoration: underline;

      &.red {
        color: color(watermelon);
      }

      &.blue {
        color: color(purple);
      }
    }
  }

  &-blacklist {
    &-button {
      @include nav-border-bottom(var(--primary-color));
      margin-right: 50px !important;
      margin-left: auto;
      width: max-content;
      background: transparent;
      cursor: pointer;
      color: var(--primary-color);
    }

    &-button p {
      margin-top: 10px;
      margin-bottom: 3px;
    }

    &-button i {
      font-size: 20px;
    }

    &-nosupplier {
      margin-left: auto;
      width: max-content;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .nav-tabs {
    margin-bottom: -15px;
    border-bottom: 0;

    .nav-item {
      border: 2px solid transparent;
    }

    .nav-item span::before {
      color: #9b9b9b;
    }

    .nav-item .active {
      @include nav-border-bottom(var(--primary-color), true);
      border-color: transparent;
      background: transparent;
      color: var(--primary-color);
    }

    .nav-item .active span::before {
      color: var(--primary-color);
    }
  }

  .content-table {
    padding-top: 50px;
  }
}
