.onboarding {
  position: relative;
  width: 100vw;
  min-height: 100vh;

  &-tour {
    z-index: 5;
    left: 0;

    .layout-page .side-layout {
      position: unset;
      height: unset;
      overflow-x: hidden;
    }
  }

  &__logo {
    width: 80px;
  }

  &-main {
    @include respond('xl-') {
      padding: 20px 20% 0;
    }
    @include respond('as') {
      padding: 40px 5% 0;
    }
    padding: 20px 25% 0;

    &__header {
      margin: 20px auto;
      padding: 20px 0;
      text-align: center;

      .title {
        @include respond('as') {
          font-size: 2.5rem;
        }
        margin-bottom: 10px;
        line-height: 1.3;
        font-size: 3rem;
        font-weight: 600;
        color: color(black);
      }

      .subtitle {
        line-height: 1.2;
        font-size: 1.7rem;
        color: color(grey);
      }
    }

    &__body {
      @include respond('as') {
        margin-bottom: 30px;
      }

      margin: 0 auto 30px;
    }
  }

  &-success {
    @include respond('xl-') {
      padding: 15rem 5% 0;
    }
    padding: 15rem 20% 0;
    width: 100%;

    &__checker {
      @include flex-display(row);
      margin: 0 auto;
      border-radius: 50%;
      padding: 10px;
      width: 100px;
      height: 100px;
      background-color: lighten(color(purple), 25%);

      i {
        @include font-size(x-large);
      }
    }

    &__buttons {
      @include respond('xs') {
        flex-direction: column;
        width: 80%;
      }
      display: flex;
      justify-content: center;
      margin: 0 auto;
      width: 75%;

      div {
        @include respond('xs') {
          margin: 0 0 10px;
          width: 100%;
        }
        margin: 5px 10px;
        width: 45%;

        .btn {
          width: 100%;
        }
      }
    }
  }

  &-setup {
    @include respond('as') {
      padding: 5rem 1rem;
    }
    position: relative;
    padding: 5rem 3rem 10rem;
    width: 100%;
    background: color(light-blue);

    .dashboard-nav {
      @include respond('ab') {
        display: none;
      }

      position: absolute;
      top: 0;
      left: 0;
    }

    &__title {
      @include respond('as') {
        display: none;
      }
      z-index: 15;
      position: fixed;
      top: 0;
      border-bottom: 1px solid color(light-grey);
      padding: 5rem 0 10px;
      width: 90%;
      background: color(light-blue);

      span {
        text-transform: capitalize;
        font-size: get-font-size(large);

        &:last-child {
          font-size: get-font-size(medium);
          color: color(grey);
        }
      }
    }

    .title-onboarded {
      z-index: 2;
      left: 13rem;
      width: 87%;
    }

    &__content {
      @include respond('as') {
        top: 1rem;
        padding: 5px;
        width: 100%;
      }

      position: relative;
      top: 5rem;
      padding: 20px 10px 20px 5px;
      width: 84%;
      overflow-x: hidden;

      // .content {
      //   border: 1px solid #000;

      //   &__table {
      //     position: relative;
      //     margin: 10px 0 30px;
      //     box-shadow: 0 5px 10px rgba(0, 0, 0, 0.08);
      //     border-radius: 4px;
      //     padding: 2rem;

      //     min-height: 80vh;
      //     overflow: auto;
      //     background: color(white) !important;

      //     .title {
      //       margin: 0 10px 20px;
      //       text-transform: capitalize;
      //       font-size: get-font-size(medium);
      //       font-weight: get-font-weight(regular);
      //     }
      //   }

      //   &__page-controls {
      //     margin-top: 1000px;
      //   }
      // }
    }

    .content-onboarded {
      position: relative;
      top: 8rem;
      left: 10rem;
      width: 90%;
    }

    &__sidebar {
      @include respond('as') {
        display: none;
      }

      position: fixed;
      top: 12rem;
      right: 0;
      margin-top: 10px;
      width: 17%;

      .title {
        margin-bottom: 10px;
        padding: 5px 20px;
        text-transform: uppercase;
        font-size: get-font-size(small);
      }

      .progress-list {
        border-left: 1px solid color(light-grey);
        padding: 10px 20px;
        height: 75vh;
        overflow: auto;

        &__item {
          margin-bottom: 1.5rem;
          list-style: none;
          transition: transform 0.4s;

          &:hover {
            transform: translateY(-2px);
          }
        }

        &__link {
          cursor: pointer;
          text-decoration: none;
          text-transform: capitalize;
          font-size: get-font-size(medium);
          color: color(grey);
          transition: color 0.3s;

          &.active {
            font-weight: get-font-weight(semi-bold);
            color: theme(secondary);
          }

          &.completed {
            color: theme(primary);
          }
        }
      }
    }
  }

  &-tabs {
    @include respond(as) {
      padding-left: 15px;
    }

    padding: 30px 0 0 100px;

    .nav {
      justify-content: space-around;

      &-tabs {
        @include respond(xs) {
          margin-left: -30px;
          padding-left: 49px;
          width: 100vw;
        }
      }

      &-item {
        @include respond(as) {
          width: 40px;
        }
        position: relative;
        display: block;

        .active {
          @include nav-border-bottom(color(purple));
          border-color: transparent;
          background-color: transparent;
          color: color(black);
        }

        .active.nav-link::after {
          border: 1px solid color(dark);
        }
      }

      &-link {
        @include respond(as) {
          width: 55px;
          height: 55px;
        }
        @include font-size(medium);
        display: block;
        padding: 0 0 24px;
        color: color(grey);

        &:hover {
          border-color: transparent;
        }

        &-check {
          @include respond(as) {
            margin-left: 8px;
            width: 40px;
            height: 40px;
          }
          z-index: 100;
          display: flex;
          align-items: center;
          justify-content: center;
          float: left;
          margin-right: 10px;
          border-radius: 100%;
          border: 1px solid color(purple);
          width: 25px;
          height: 25px;
          background-color: color(onboarding-tabs);
          text-align: center;

          .mark {
            @include font-size(x-small);
            z-index: 101;
            background: transparent;
            color: color(purple);
          }

          &.mob-only {
            @include respond(as) {
              display: block;
              border: 1px solid color(light-grey);
              background-color: transparent;
            }
            display: none;
          }

          &.mob-only .mark {
            display: none;
          }
        }

        &.no-after::after {
          @include respond(as) {
            display: block;
            border: 1px solid color(light-grey);
            background-color: transparent;
          }
          display: none;
        }

        .title {
          @include respond(as) {
            display: none;
          }

          &-mobile {
            @include respond(as) {
              display: inline;
              vertical-align: sub;
            }
            display: none;
          }
        }

        @include respond(as) {
          &::after {
            top: -10px;
            left: 0;
          }
        }
      }
    }
  }

  &-logo {
    @include respond(am) {
      top: 15px;
      width: 46px;
      left: calc(50% - 23px);
    }
    position: absolute;
    top: 45px;
    width: 80px;
    left: 0px;
  }
}

.appointments {
  .table {
    &-body {
      td:nth-child(1),
      td:nth-child(2) {
        text-transform: capitalize;
      }
    }
  }
}

.content-table {
  @include respond('as') {
    margin-bottom: 15rem;
    box-shadow: none !important;
    border: 0 !important;
    padding-top: 1.5rem;
    background-color: transparent !important;
  }

  position: relative;
  margin: 1.5rem 0;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  border: 1px solid #eaf0f7;
  padding: 3rem 0 0;
  min-height: 80vh;
  //overflow-x: auto;
  background: color(white) !important;

  &--flex {
    display: flex;
    align-items: stretch;
  }

  &__inner {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 1.5rem;
  }

  &__title {
    @include respond('as') {
      margin-left: 0;
    }

    margin: 0 0 1.5rem 4rem;
    text-transform: capitalize;
    font-size: get-font-size(medium);
    font-weight: get-font-weight(regular);
    color: #67758d;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    padding: 0 1.5rem;
  }
}

.onboarding_logout_btn {
  @include respond(xs) {
    display: none !important;
  }
  display: block;
}
