.patients-admission {
  .patients-prescription {
    .prescription-item {
      margin-bottom: 3rem;
      box-shadow: 0 2px 4px rgba(106, 105, 228, 0.03);
      border-radius: 7px;
      border: 1px solid rgba(67, 102, 118, 0.148353);
      padding: 2rem;
      background: color(white);

      &__group:not(:last-child) {
        margin-bottom: 2rem;
      }

      &__records {
        @include flex-display(row);

        &-files {
          @include flex-display(row);
          flex: 1;
          flex-wrap: wrap;
          justify-content: flex-start;
          margin-right: 5px;
        }

        &-file {
          margin: 1rem;
          margin-left: 0;
          border-radius: 7px;
          border: 1px solid rgba(67, 102, 118, 0.148353);
          width: 128px;
          height: 79px;
          overflow: hidden;
          background: #e2e2f9;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        &-count {
          @include round-image(34px, contain);
          @include flex-display(row);
          box-shadow: 0 2px 4px rgba(106, 105, 228, 0.03);
          background: #e2e2f9;
        }
      }
    }
  }

  .admission-card {
    position: relative;
    // margin-bottom: 4.5rem;
    box-shadow: 0 2px 10px rgba(67, 102, 118, 0.148353);
    border-radius: 7px;
    padding-top: 3rem;
    height: 100%;
    overflow: hidden;
    background: color(white);

    &__edit {
      position: absolute;
      top: 3rem;
      right: 2rem;

      .more-options {
        margin-top: 0.2rem;
      }
    }

    &__status {
      position: absolute;
      top: 2rem;
      right: 2rem;
      border-radius: 54px;
      padding: 0.5rem 1.5rem;
      background: rgba(80, 227, 194, 0.1);
      font-size: 14px;
      color: #72ccc5;

      &.done {
        background: rgba(80, 227, 194, 0.1);
        color: #72ccc5;
      }

      &.pending {
        background: rgba(245, 166, 35, 0.1);
        color: #f5a623;
      }
    }

    &__group {
      padding: 0 2.5rem;
      padding-right: 5rem;

      &--header-pill {
        margin-bottom: 0.5rem;
        border-radius: 7px;
        padding: 0.5rem 1rem;
        max-width: max-content;
        background-color: theme(secondaryShade);
        color: theme(secondary);

        &.purple {
          background: rgba(106, 105, 228, 0.103119);
        }

        &.pink {
          background: rgba(255, 116, 115, 0.578323);
        }

        &.yellow {
          background: rgba(253, 216, 53, 0.4);
        }

        &.green {
          background: #9ebda5;
        }
      }
    }

    &__footer {
      @include flex-display(row);
      justify-content: flex-end;
      padding: 0 2rem;
      height: 4.8rem;
      background: rgba(106, 105, 228, 0.103119);
    }
  }

  .table {
    &-header {
      background-color: transparent !important;
    }

    &-body {
      padding: 4rem !important;

      tr {
        background-color: #fafafe;

        &:nth-child(1) {
          td {
            padding-top: 2rem !important;
          }
        }
      }
    }

    &-cell {
      border-bottom: 0 !important;
      vertical-align: middle;
    }
  }
}

.patients-referral-form {
  padding: 4rem;

  &__title {
    text-align: center;
    line-height: 25px;
    font-size: 20px;
    color: color(black);
  }

  &__logo {
    max-width: 10.5rem;
    max-height: 10.5rem;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__hospital-info {
    margin: 2rem 0 1rem;
    border-radius: 4px;
    padding: 1.5rem 2rem;
    background: rgba(67, 102, 118, 0.148353);
  }

  &__patient-info {
    border: 1px solid rgba(67, 102, 118, 0.148353);
    border-width: 1px 0;
    padding: 2rem 0;
  }

  .form__settings-footer {
    margin-top: 5rem;
  }

  &__button {
    color: color(purple) !important;
    background: transparent !important;
    border-color: color(purple) !important;
    border-radius: 7px !important;
  }
}

.patients-casenote-form {
  &__card {
    box-sizing: border-box;
    margin-bottom: 3rem;
    border-radius: 4px;
    border: 1.5px solid rgba(67, 102, 118, 0.148353);
    background: #fff;
  }

  .medical-review {
    &__header {
      @include flex-display(row);
      justify-content: space-between;
      border-bottom: 1px solid rgba(155, 155, 155, 0.288525);
      padding: 2rem;
    }

    &__main {
      padding: 2rem;

      &-group {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 1.5rem;
      }

      &-item {
        @include font-size(regular);
        padding: 0 1.5rem 0.25rem;
        line-height: 20px;
        color: color(dark);

        &:not(:last-child) {
          border-right: 1px solid #979797;
        }
      }

      &-title {
        @include font-weight(regular);
        border-radius: 6px;
        border-right: 0 !important;
        padding: 1.2rem 1.5rem;

        &.purple {
          background: rgba(106, 105, 228, 0.2);
          color: #6a69e4;
        }

        &.blue {
          background: rgba(50, 50, 124, 0.2);
          color: #32327c;
        }

        &.green {
          background: rgba(88, 201, 185, 0.2);
          color: #58c9b9;
        }

        &.orange {
          background: rgba(228, 169, 71, 0.3);
          color: rgba(245, 166, 35, 0.671988);
        }
      }
    }
  }

  .casenote {
    &__header {
      border-bottom: 1px solid rgba(155, 155, 155, 0.288525);
      padding: 2rem;

      &-title {
        display: flex;
        align-items: center;
        line-height: 25px;
        font-size: 20px;
        color: color(primary);

        .iconmoon {
          font-size: 25px;
        }
      }
    }

    &__main {
      padding: 2rem;

      .section-card {
        box-sizing: border-box;
        border-radius: 4px;
        border: 1.5px solid rgba(67, 102, 118, 0.148353);
        padding: 1.5rem 2.5rem;
        background: color(white);

        &__header {
          @include flex-display(row);
          justify-content: space-between;

          i {
            padding-top: 0.5rem;
            font-size: 2rem;
          }
        }

        &__content {
          margin-top: 1.5rem;

          .edit-btn {
            margin-left: auto;
            border-bottom: 2px solid theme(primary);
            max-width: max-content;
            cursor: pointer;
          }

          .option-pill {
            @include flex-display(row);
            border-radius: 4px;
            padding: 1rem 1.5rem;
            max-width: max-content;
            background: rgba(106, 105, 228, 0.08);
          }
        }
      }
    }

    &__footer {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding: 0 2rem 2rem;

      &--button {
        margin-right: 1rem;
        margin-bottom: 1rem;
        text-align: center;
      }
    }
  }
}

.ward-round-form {
  &__content {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3rem;

    div:nth-child(1) {
      flex: 1;
      margin-right: 10rem;
    }

    div:nth-child(2) {
      width: 25rem;
    }
  }
}

.add-prescription-dosage {
  position: relative;
  border: 1px solid color(light-grey);
  padding: 2.9rem 2rem 0;

  &__heading {
    position: absolute;
    top: -1.75rem;
    left: 5rem;
    padding: 0.5rem 1rem;
    background-color: color(white);
    text-align: center;
  }
}

.table-horizontal-scroll {
  display: block;
  overflow-x: auto;
  white-space: nowrap
}

@keyframes add-physician-note {
  from {
    opacity: 0.1;
    transform: translateY(-10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.complaint {
  &-header {
    font-size: 14px;
    color: #9b9b9b;
  }

  &-checkbox {
    @include flex-display(row);
    position: relative;

    input {
      position: absolute;
      left: 1.5rem;
      width: 2rem;
      height: 2rem;
      opacity: 0;
    }

    &__box {
      @include flex-display(row);
      margin-right: 1rem;
      border-radius: 4px;
      border: 1px solid rgba(67, 102, 118, 0.148353);
      width: 2rem;
      height: 2rem;
      z-index: 0;

      .check-icon {
        display: none;
        font-size: 1rem;
        color: theme(primary);
      }

      &.checked {
        border: 1px solid theme(primary);

        .check-icon {
          display: inline-block;
        }
      }
    }
  }
}
