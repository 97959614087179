@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}

// 1. Configuration and helpers
@import 'abstracts/variables';
@import 'abstracts/functions';
@import 'abstracts/mixins';
@import 'abstracts/animations';

// 2. Vendors
@import '~hamburgers/dist/hamburgers.css';
@import '~bootstrap/dist/css/bootstrap.css';
@import '~react-day-picker/lib/style.css';
@import '~react-toastify/dist/ReactToastify.css';
@import '~react-datepicker/dist/react-datepicker.css';

@import 'vendors/icomoon';
@import 'vendors/rc-time-picker';
@import 'vendors/day-picker';

// 3. Base stuff
@import 'base/base';
@import 'base/fonts';
@import 'base/typography';
@import 'base/helpers';

// 4. Layout-related sections
@import 'layout/header';
@import 'layout/plain';
@import 'layout/layout-main';
@import 'layout/footer';
@import 'layout/scrollbar';

// 5. Components
@import 'components/index';

// 6. Page-specific styles
@import 'pages/auth';
@import 'pages/dashboard';
@import 'pages/errorPage';
@import 'pages/settings/index';
@import 'pages/settings/staff';
@import 'pages/supplier';
@import 'pages/user-profile';
@import 'pages/procedure-result-form';
@import 'pages/procedure-item';
@import 'pages/support';
@import 'pages/aggrements';
