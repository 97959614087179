.shift-form {
  .day-title {
    text-transform: capitalize;
  }

  .frequency-input {
    padding: 0 0.5rem;
  }

  .shift-delete-button,
  .shift-add-button {
    border: 0;
    background: transparent;
  }

  .shift-delete-button {
    margin-bottom: 3rem;
    color: color(dark);
    transition: color 0.3s ease;

    &:hover {
      color: color(red);
    }
  }

  .shift-add-button {
    margin-bottom: 2rem;
    padding-left: 15px;
    color: color(primary);

    span {
      display: inline-block;
      margin-right: 10px;
      vertical-align: baseline;
    }
  }

  .time-row,
  .time-column-block {
    display: flex;
  }

  .time-column {
    flex: 0 0 50%;
    padding: 0 15px;
    width: 50%;

    &-block {
      width: calc(100% - 50px);
    }

    .form__label {
      padding-top: 0.5rem;
    }
  }
}
