.transfer-patient {
  margin-top: -1rem;

  &__search {
    @include flex-display(row);

    .dashboard-search-bar {
        flex: 1;
        margin-right: 1rem;
        border: 0;
    }
  }

  &__consultant,
  &__vital-notice {
    border-radius: 5px;
    padding: 0.5rem 1.5rem;
    max-width: max-content;
  }

  &__vital-notice {
    margin-bottom: 5rem;
    background-color: theme(primaryShade);
    color: theme(primary);
  }

  &__consultant {
    margin-bottom: 1.5rem;
    background-color: theme(secondaryShade);
    color: theme(secondary);
  }

  &__tab-option {
    margin-bottom: 1.5rem;

    .header {
      @include flex-display(row);
      justify-content: flex-start;

      color: color(grey);

      i {
        padding-top: 5px;
        font-size: 2rem;
      }
    }

    .body {
      padding-left: 2.5rem;
    }
  }
}
