$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);

.plain-layout {
  .logoImage {
    width: 100%;
  }
}

.generic-tab-nav {
  margin-bottom: -1.35rem;

  &__tab-content {
    padding: 3rem;

    .details-section {
      @include flex-display(row);
      margin-top: 2rem;

      &__group {
        flex: 1;
        align-self: stretch;
        padding: 0 3rem;

        &:first-child {
          padding-left: 0;
        }

        &:not(:last-child) {
          border-right: 1px solid color(light-grey);
        }
      }
    }

    .form-group {
      margin-bottom: 1rem;
    }

    .appointments-today {
      border-bottom: 1px solid color(light-grey);
      padding: 1rem 0 3rem;
      &--no-border {
        border-bottom: unset;
        margin-bottom: 0;
      }
    }
  }
}

.custom_profile_tab_padding {
  padding: 0 !important;

  @include respond('md') {
    padding: 0 3rem !important;
  }
}
