// -----------------------------------------------------------------------------
// This file contains styles that are specific to the dashboard page.
// -----------------------------------------------------------------------------
.dashboard {
  @include respond('as') {
    flex-direction: column;
  }

  position: relative;
  display: flex;
  width: 100vw;
  min-height: 100vh;

  &-tour {
    .main-layout {
      background: color(white);
    }

    .hospital .content__page-controls {
      display: none;
    }

    &-over {
      // z-index: 2;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(77, 76, 98, 0.4);
    }

    .navigation-otherlinks .show {
      @include respond('as') {
        top: 8rem;
        left: 170px;
      }
      position: fixed;
      top: 135px;
      height: max-content;
    }
  }

  &-tooltip {
    @include respond('as') {
      width: 113vw;
      height: 100vh;
    }
    z-index: 3;
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: rgba(color(active), 0.55);
  }

  &__component {
    @include respond('as') {
      top: 5rem;
      left: -100rem;
      width: 100%;
      height: calc(100vh - 5rem);

      &.active {
        left: 0;
      }
    }

    z-index: 2;
    position: fixed;
    top: 0;
    left: -25rem;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 2px 0 rgba(color(black), 0.25);
    width: 25rem;
    height: 100vh;
    background: #f9f9fe;
    transition: left 0.3s;

    &.active {
      left: 0;
    }

    .component {
      @include respond('xs') {
        padding-bottom: 2rem;
      }

      &__body {
        flex: 1;
        padding: 1rem 1.5rem;
        height: calc(100vh - 4.5rem);
        overflow: auto;

        .settings-drawer {
          overflow: auto;

          &__nav-list {
            margin: 0 auto;
            padding: 10rem 2rem;
            width: 90%;
            list-style: none;
          }

          &__nav-item {
            position: relative;
            margin: 15px 0;
          }

          &__nav-link {
            display: block;
            text-transform: capitalize;
            font-size: 1.7rem;
            font-weight: 400;
            color: color(grey);

            &:hover,
            &.active {
              text-decoration: none;
              color: theme(primary);

              &::after {
                position: absolute;
                bottom: -2px;
                left: 0;
                width: 70%;
                height: 4px;
                background: theme(primary);
                content: '';
              }
            }
          }
        }
      }

      &__form {
        margin-top: 2rem;
      }

      &__header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid rgba(#d8d8d8, 0.3);
        padding: 1rem 1.5rem;
        height: 4.5rem;
      }

      &__image {
        @include round-image(8rem, cover);
        margin: 0 auto;
        margin-bottom: 0.5rem;
        cursor: pointer;

        input {
          display: none;
        }

        &-block {
          margin-bottom: 2rem;
        }
      }

      &__title {
        margin-bottom: 0;
        font-size: 1.6rem;
        font-weight: 600;
        color: color(dark);
      }
    }
  }

  &-content {
    @include respond('as') {
      padding: 7rem 0 0;
    }

    display: flex;
    flex-direction: column;
    padding: 4rem 4rem 0 14rem;
    width: 100%;
    background: color(light-blue);
  }

  &-header {
    margin-bottom: 3rem;

    &__title {
      text-transform: capitalize;
      font-size: 2rem;
      font-weight: 600;
      color: color(black);
    }

    &__subtitle {
      margin-bottom: 0;
      height: 25px;
      font-size: 1.6rem;
      font-weight: 300;
      color: color(grey);
    }
  }

  &-header-link {
    @include nav-border-bottom(theme(primary));
    padding: 0 0.3rem 0.6rem;
    cursor: pointer;
    color: theme(primary);

    &:hover {
      text-decoration: none;
    }
  }

  &-header-goback {
    display: inline-flex;
    cursor: pointer;
    transition: opacity 0.3s;

    &:hover {
      opacity: 0.75;
    }
  }

  &-search {
    margin-bottom: 2rem;
    border-radius: 500px;
    border: 1px solid color(light-grey);
    overflow: hidden;

    &__bar {
      border: 0;
      padding: 1rem 2rem;
      width: 100%;
      font-size: 1.4rem;

      &:focus {
        outline: none;
      }

      &::placeholder {
        color: color(grey);
      }
    }
  }

  &-search-bar {
    position: relative;
    border-radius: 3px;
    border: 1px solid rgba(162, 162, 186, 0.21);
    padding-left: 2rem;
    overflow: hidden;
    background: color(white);
    width: 100%;

    svg {
      position: absolute;
      top: 1rem;
      left: 0.5rem;
      transform: translate(50%, 50%);
    }

    &__bar {
      border: 0;
      padding: 1.5rem;
      width: 100%;
      font-size: 1.4rem;
      color: color(grey);

      &:focus {
        outline: none;
      }

      &::placeholder {
        text-transform: none;
        color: color(grey);
      }
    }
  }

  &-swiper {
    z-index: 0;
    position: relative;
    display: flex;
    flex: 1;
    padding-right: 0;

    .swiper-container {
      flex: 1;

      .swiper-slide {
        display: flex;
      }
    }

    @include respond('xs') {
      padding-right: 15px;
    }
  }
}

.side-drawer {
  @include respond('as') {
    width: 0 !important;
  }

  z-index: 5;
  position: fixed;
  height: 100%;
  background: #f9f9fe;
  transition: opacity 0.3s, width 0.3s;
}

.custom-tooltip {
  @include respond('as') {
    top: 13rem;
  }
  z-index: 11;
  position: relative;
  top: 19rem;
  left: 0;
  max-width: 300px;
  transition: transform 1.5s;
  // branch , roles , staffs , appointments , hmo, inventory , patient

  &-branch {
    transform: translateY(6rem);
  }

  &-department {
    transform: translateY(12rem);
  }

  &-staffs {
    transform: translateY(17rem);
  }

  &-more {
    transform: translateY(23rem);
  }

  &-appointments {
    transform: translateY(-6.5rem);
  }

  &-hmo {
    @include respond('as') {
      transform: translateY(-3rem);
    }
    transform: translateY(-4rem);
  }

  &-inventory {
    @include respond('as') {
      transform: translateY(0);
    }
    transform: translateY(0);
  }

  &-patient {
    @include respond('as') {
      transform: translateY(3rem);
    }
    transform: translateY(2.5rem);
  }

  &-branch-config {
    @include respond('as') {
      transform: translateY(6rem);
    }
    transform: translateY(5.5rem);
  }

  &-patient-plan {
    @include respond('as') {
      transform: translateY(9rem);
    }
    transform: translateY(8.5rem);
  }

  &-bank {
    transform: translateY(11.8rem);
  }

  &-category {
    @include respond('as') {
      transform: translateY(15rem);
    }
    transform: translateY(14.5rem);
  }

  &-registration-customization {
    @include respond('as') {
      transform: translateY(18.2rem);
    }
    transform: translateY(17.5rem);
  }

  &-standards {
    @include respond('as') {
      transform: translateY(21.2rem);
    }
    transform: translateY(20.3rem);
  }

  &-admission {
    @include respond('as') {
      transform: translateY(30.5rem);
    }
    transform: translateY(29.3rem);
  }

  &-settings {
    @include respond('as') {
      transform: translateY(33.5rem);
    }
    transform: translateY(32.3rem);
  }

  &__indicator {
    @include respond('as') {
      height: 3.5rem;
    }
    z-index: 2;
    position: absolute;
    left: 0;
    border: 2px solid color(white);
    height: 7rem;
  }

  &__arrow {
    @include respond('as') {
      top: 2rem;
      left: 1.5rem;
      border-color: transparent transparent white;
    }
    z-index: 2;
    position: absolute;
    left: 9rem;
    margin-top: 2rem;
    border-radius: 3px;
    border-width: 20px;
    border-style: solid;
    border-color: transparent white transparent transparent;
  }

  .expanded &__arrow {
    @include respond('as') {
      top: 1rem;
      left: 18rem;
    }
    left: 39.5rem;
  }

  .expanded &__body {
    @include respond('as') {
      top: 1rem;
      left: 18rem;
    }
    left: 43rem;
  }

  &__body {
    @include respond('as') {
      top: 2rem;
      left: 1rem;
      margin-top: 5rem;
    }
    position: absolute;
    left: 12.5rem;
    margin-top: 1rem;
    border-radius: 3px;
    padding: 3rem 2rem;
    width: 100%;
    background-color: color(white);

    .title {
      @include font-weight(regular);
      @include font-size(regular);
      text-transform: capitalize;
      color: theme(primary);
    }

    .subtitle {
      @include font-weight(regular);
      @include font-size(regular);
      margin: 15px 0;
      line-height: 1.3;
      color: color(grey);
    }

    .actions {
      display: flex;
      justify-content: space-between;

      button {
        @include font-weight(regular);
        @include font-size(small);
        border-radius: 3px;
        border: 1px solid color(indigo);
        padding: 0.5rem 1rem;
        background: transparent;
        text-transform: capitalize;
        color: color(indigo);

        &:first-child {
          border: 1px solid color(watermelon);
          color: color(watermelon);
        }

        &:hover,
        &:focus {
          outline: none;
        }
      }
    }
  }
}

.breadcrumb {
  margin-top: 1rem;
  padding: 0;
  background-color: transparent;

  &-item {
    line-height: 23px;
    letter-spacing: 0.5px;
    font-size: 18px;
    color: color(dark);
  }

  a:hover {
    text-decoration: none !important;
    color: color(dark) !important;
  }
}

.card__show-more {
  background: rgba(106, 105, 228, 0.103119);
  padding: 12px 25px;
}
