.heading {
  @media only screen and (max-width: 37.5em) {
    font-size: 1.125rem;
  }

  margin-bottom: 1.5rem;
  line-height: 1.5;
  font-size: 1.8rem;
  font-weight: 700;
}

.small-heading {
  @media only screen and (max-width: 37.5em) {
    font-size: 1.05rem;
  }

  line-height: 20px;
  font-size: 1.7rem;
  font-weight: 500;
}

.large-heading {
  @include respond('as') {
    @include font-size(large);
  }

  @include font-size(m-large);
  @include font-weight(regular);
}

.paragraph-bg {
  @include respond('as') {
    font-size: 1.95rem;
  }

  @include font-size(medium);
  margin: 0;
  line-height: 23px;
}

.paragraph {
  @include respond('as') {
    font-size: 1.75rem;
  }

  @include font-size(regular);
  margin: 0;
  line-height: 1.25;
  letter-spacing: 0.2px;
}

.paragraph-sm {
  @include respond('as') {
    @include font-size(regular);
  }

  @include font-size(small);
  margin: 0;
  line-height: 15px;
}

.paragraph-x-sm {
  @include respond('as') {
    @include font-size(small);
  }

  @include font-size(x-small);
  margin: 0;
  line-height: 12px;
}

.paragraph-sm-bg {
  @include font-size(large);
  line-height: 25.3px;
}

@for $i from 1 through 60 {
  .fs-#{$i} {
    font-size: #{$i}px !important;
  }
}

@for $i from 1 through 7 {
  .fw-#{$i} {
    font-weight: #{$i * 100};
  }
}
