.batch-selector {
  position: relative;
  margin-top: -2px;

  &-input {
    display: flex;
    justify-content: space-between;
    border-radius: 10px;
    border: 1px solid color(grey);
    padding: 5px;
    padding-left: 10px;
    min-width: 120px;
    max-width: 120px;

    span {
      overflow: hidden;
      cursor: pointer;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: rgba(0, 0, 0, 1);
    }

    &__field {
      box-shadow: none;
      border: 0;
      padding: 0;
      width: 100%;
      background: transparent;
      text-align: center;
      letter-spacing: 0.5px;
      font-size: 1.4rem;
      font-weight: 400;
      color: color(dark);

      &::placeholder {
        color: rgba(color(dark), 0.4);
      }

      &:focus,
      &:active {
        outline: none;
        box-shadow: none;
        border: 0;
      }
    }

    i {
      margin-left: 10px;
      color: color(grey);

      &:first-child {
        margin-right: 10px;
        margin-left: 0;
      }

      &.align {
        align-self: center;
      }
    }
  }

  .context-menu-body {
    left: 0;
    margin-top: 0;
    box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12),
      0 5px 5px -3px rgba(0, 0, 0, 0.2);
    min-width: 100%;
    max-width: 400px;
  }

  &-options {
    right: 0 !important;
    margin-top: 10px;
    width: 100%;
  }

  tr:first-child p {
    padding-top: 10px;
  }

  tr:hover {
    color: black;
  }

  td {
    max-width: 110px;
    font-size: 14px !important;

    p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

    }

  }
}
