.card__onboarding {
  @include respond('as') {
    flex-direction: column;
    padding: 30px;
  }
  @include flex-display(row);
  box-shadow: 1px 3px 12px rgba(0, 0, 0, 0.09);
  border-radius: 4px;
  border: 1px solid transparent;
  padding: 20px 30px;

  .avatar {
    @include flex-display(row);
    margin-right: 20px;
    border-radius: 50%;
    padding: 10px;
    width: 60px;
    height: 60px;
    background-color: lighten(color(purple), 25%);
  }

  .content {
    @include respond('as') {
      margin: 10px 0 20px;
      text-align: center;
    }
    margin-right: auto;
    padding: 10px 10px 0;

    .title {
      text-transform: capitalize;
      line-height: 1.2;
      font-size: 2.4rem;
    }

    .subtitle {
      @include respond('as') {
        font-size: 1.4rem;
      }
      line-height: 1.1;
      font-size: 1.6rem;
    }
  }
}
