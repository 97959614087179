.loader {
  @include flex-display(row);
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  background: color(white);
  transform: translate(-50%, -50%);

  &-block {
    @include respond('xs') {
      min-height: 200px;
    }

    position: relative;
    width: 100%;
    height: 100%;
    min-height: 500px;
  }

  &__small-block {
    min-height: 200px;
  }

  .lds-roller {
    position: relative;
    display: inline-block;
    width: 64px;
    height: 64px;
  }

  .lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 32px 32px;
  }

  .lds-roller div::after {
    position: absolute;
    display: block;
    margin: -3px 0 0 -3px;
    border-radius: 50%;
    width: 6px;
    height: 6px;
    background: theme(secondary);
    content: ' ';
  }

  .lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
  }

  .lds-roller div:nth-child(1)::after {
    top: 50px;
    left: 50px;
  }

  .lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
  }

  .lds-roller div:nth-child(2)::after {
    top: 54px;
    left: 45px;
  }

  .lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
  }

  .lds-roller div:nth-child(3)::after {
    top: 57px;
    left: 39px;
  }

  .lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
  }

  .lds-roller div:nth-child(4)::after {
    top: 58px;
    left: 32px;
  }

  .lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
  }

  .lds-roller div:nth-child(5)::after {
    top: 57px;
    left: 25px;
  }

  .lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
  }

  .lds-roller div:nth-child(6)::after {
    top: 54px;
    left: 19px;
  }

  .lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
  }

  .lds-roller div:nth-child(7)::after {
    top: 50px;
    left: 14px;
  }

  .lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
  }

  .lds-roller div:nth-child(8)::after {
    top: 45px;
    left: 10px;
  }

  @keyframes lds-roller {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  &-modal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.page-loader {
  @include flex-display(column);
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: theme(primary);

  &__dot {
    margin-right: 3px;
    border-radius: 50%;
    width: 5px;
    height: 5px;
    background-color: color(white);
    opacity: 0;
    animation: slideUp 0.8s infinite ease-in-out;

    &.red {
      background-color: color(red);
    }

    &:nth-of-type(2) {
      margin-left: 1rem;
    }

    $delay: 0s;

    @for $i from 2 through 4 {
      $delay: $delay + 0.2s;

      &:nth-of-type(#{$i}) {
        animation-delay: $delay;
      }
    }
  }

  &__logo {
    margin-bottom: 2rem;
    width: 100px;
    animation: pop 2s infinite ease-in-out;
  }

  .shape {
    fill: color(white);
  }

  &__text {
    display: flex;
    align-items: baseline;
    color: color(white);
  }
}

@keyframes slideUp {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes pop {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.2, 1.2);
  }

  100% {
    transform: scale(1, 1);
  }
}
