.hospital {
  height: 100%;

  &-main {
    @include respond('as') {
      flex-direction: column;
      margin-bottom: 10rem;
      height: auto;
    }
    display: flex;
    justify-content: space-around;
    margin-bottom: 5rem;

    &__setup {
      @include respond('as') {
        margin-bottom: 20px;
        width: 100%;
      }
      width: 57%;
    }

    &__colors {
      @include respond('as') {
        width: 100%;
      }
      width: 40%;

      &-primary,
      &-secondary {
        margin: 5px auto 30px;
        width: 90%;
      }
    }
  }
}

.color-picker {
  border-radius: 4px;
  border: 1px solid rgba(67, 102, 118, 0.15);
  padding: 0.5rem 1.5rem;
  width: 100%;
  background-color: color(white);

  &__title {
    margin-bottom: 10px;
    text-transform: capitalize;
    letter-spacing: 1px;
    font-size: 1.2rem;
    color: color(grey);
  }

  &__selector {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 5px;

    .color-pallete {
      &__primary,
      &__secondary {
        border-radius: 50%;
        width: 20px;
        height: 20px;
      }

      &__primary {
        background: theme(primary);
      }

      &__secondary {
        background: theme(secondary);
      }
    }

    .color-value {
      margin-right: auto;
      box-shadow: none;
      border: 0;
      padding: 0 10px;
      background: transparent;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-size: get-font-size(small);
      font-weight: 600;
      color: color(dark);

      &:focus,
      &:active {
        outline: none;
        box-shadow: none;
        border: 0;
      }
    }

    .color-picker {
      position: absolute;
      top: 0;
      right: 0;
      width: 5%;
      height: 2rem;
      opacity: 0;
    }

    svg {
      width: 1rem;
      height: 2rem;
    }
  }
}
