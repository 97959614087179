.variant-preview-table {
  margin-bottom: 3rem;
  width: 100%;

  .heading {
    background-color: color(off-purple);
  }
}

.variant-preview-item {
  margin-bottom: 2rem;
  width: 100%;

  .heading {
    background-color: transparent;

    &-input {
      @include respond('xs') {
        width: 70px;
      }

      box-sizing: border-box;
      border-radius: 4px;
      border: 1px solid rgba(67, 102, 118, 0.148353);
      padding: 0.25rem 0.5rem;
      width: 135px;
    }
  }
}
