.navigation {
  &-sidebar,
  &-otheritems {
    @include respond('as') {
      left: -10rem;
    }

    padding-left: 10px;
    padding-right: 10px;
    z-index: 5;
    top: 0;
    left: 0;
    border-bottom: 1px solid color(grey);

    // overflow-x: hidden; /* Disable horizontal scroll */
    transition: left 0.3s;

    -ms-overflow-style: none; //IE and Edge
    scrollbar-width: none; //Firefox

    &::-webkit-scrollbar {
      display: none;
    }

    // &.active {
    //   @include respond('as') {
    //     left: -15rem;
    //   }
    //   left: 25rem;
    // }

    &__logo {
      @include respond('as') {
        display: none;
      }
      display: flex;
      justify-content: center;
      margin-bottom: 2rem;
      width: 100%;

      -webkit-transition: all 250ms ease-out;
      -moz-transition: all 250ms ease-out;
      -o-transition: all 250ms ease-out;
      transition: all 250ms ease-out;

      img {
        max-width: 5rem;
        object-fit: contain;
      }

      &-center {
        justify-content: center;
      }
    }

    &__nav {
      margin: 1rem 0 0;
      padding: 0;

      .nav {
        &-link {
          padding: 15px;
          margin-bottom: 10px;
          cursor: pointer;
          transition: background-color 0.2s;
          color: #f9f9fe;

          i {
            margin-right: 10px;
            display: block;
          }

          &-text {
            text-transform: capitalize;
            font-size: 12px;
            white-space: pre;
          }

          &-display {
            opacity: 0;
            -webkit-transition: all 100ms linear;
            -moz-transition: all 100ms linear;
            -o-transition: all 100ms linear;
            transition: all 100ms linear;
          }

          &:hover,
          &.active {
            @include border-radius(8px);
            background-color: color(active);
            opacity: 0.85;
            text-decoration: none;
          }
        }
      }
    }
  }

  &-switcher {
    @include border-radius(8px);
    box-shadow: 0 2px 10px rgba(67, 102, 118, 0.148353);
    background-color: theme(primary);
    color: color(white);
    font-size: 12px;
    position: relative;

    &:hover {
      background-color: color(active);
    }

    &-header {
      padding: 15px;
      white-space: pre;
      cursor: pointer;
    }

    &-initial {
      text-align: center;
      display: block;
      width: 100%;
    }

    &-title {
      color: color(dark-purple);
      font-size: 10px;
      border-top: 1px solid rgba(120, 137, 149, 0.25);
      padding: 15px 15px 0 15px;
    }

    &-items {
      display: none;
      position: absolute;
      top: 40px;
      width: 100%;
      z-index: 2;
      background-color: color(active);
      color: color(white);
      max-height: 70vh;
      overflow-y: scroll;
      
      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        width: 5px;
        border: 7px solid rgba(204, 204, 204, 0.156);
        box-shadow: inset 0 0 2.5px 2px rgba(0, 0, 0, 0.5);
      }

      &::-webkit-scrollbar-thumb {
        background: lighten(color(white), 40%);
        border-radius: 8px;
      }

      -moz-border-radius-bottomright: 8px;
      -webkit-border-bottom-right-radius: 8px;
      border-bottom-right-radius: 8px;
      -moz-border-radius-bottomleft: 8px;
      -webkit-border-bottom-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    .show-padding-bottom {
      padding-bottom: 25px;
    }

    .show {
      display: block;
    }

    &-divider {
      display: block;
      width: 100%;
    }

    &__options {
      list-style: none;
      padding: 0 15px;

      li {
        padding: 5px 0;

        a {
          color: color(white);
        }
      }

      &-link {
        cursor: pointer;
        width: 100%;
      }
    }

    .switch-link {
      cursor: pointer;

      // &:hover {
      //   text-decoration: underline;
      // }
    }
  }

  &-otherlinks {
    @include border-radius(8px);
    background-color: color(indigo);
    color: color(white);
    font-size: 12px;
    position: relative;

    &__items {
      @include border-radius(8px);
      display: none;
      position: absolute;
      bottom: -124px;
      left: 200px;
      width: 194px;
      z-index: 2;
      background-color: color(off-white);
      color: color(gray);
      box-shadow: 0px 1px 15px rgba(67, 102, 118, 0.1484);
    }

    .show {
      display: block;
    }

    .arrow-left {
      position: absolute;
      bottom: 136px;
      right: 193px;
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-right: 10px solid color(off-white);
    }

    &__options {
      list-style: none;
      padding: 0 15px;
      margin-top: 1.5rem;

      li {
        padding: 7px;

        a {
          color: color(white);
        }

        &:hover {
          @include border-radius(8px);
          color: color(white);
          background-color: color(lavender-blue);
        }
      }

      &-link {
        cursor: pointer;
        width: 100%;
      }
    }
  }

  &-otheritems {
    padding-top: 15px;
    border-bottom: 1px solid transparent;

    &__items {
      list-style: none;
    }

    .notification {
      padding: 15px;

      &-item {
        color: color(white);

        i {
          margin-right: 10px;
          display: block;
        }

        &-text {
          text-transform: capitalize;
          font-size: 12px;
          white-space: pre;
        }

        &-display {
          opacity: 0;
          -webkit-transition: all 100ms linear;
          -moz-transition: all 100ms linear;
          -o-transition: all 100ms linear;
          transition: all 100ms linear;
        }
      }
    }

    .avatar {
      padding: 15px;

      &-box {
        @include round-image(3.9rem, cover);
        border: 1px solid color(white);
        user-select: none;
      }

      img {
        border-radius: 50%;
        width: 25px;
        height: 25px;
        border: 1px solid color(white);
        object-fit: cover;
        margin-right: 10px;
        border: 1px solid color(white);
      }

      // &-main {
      //   @include round-image(25px, cover);
      //   user-select: none;
      // }

      &-details {
        width: 100%;
      }

      &-text {
        color: color(white);
        text-transform: capitalize;
        font-size: 12px;
        white-space: pre;
      }

      &-display {
        opacity: 0;
        -webkit-transition: all 100ms linear;
        -moz-transition: all 100ms linear;
        -o-transition: all 100ms linear;
        transition: all 100ms linear;
      }
    }
  }

  &-nav {
    @include respond('as') {
      display: block;
    }

    z-index: 1;
    position: fixed;
    display: none;
    width: 100%;
    background: theme(primary);

    &__bar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.5rem 0;
      height: 5rem;
    }

    &__ham {
      display: flex;
      flex-basis: 30%;
      padding: 0;

      &:focus {
        outline: none;
      }

      span.hamburger-box {
        width: 2.4rem;
        height: 1.8rem;
      }

      span.hamburger-inner,
      span.hamburger-inner::before,
      span.hamburger-inner::after {
        width: 2.4rem;
        height: 2px;
        background: color(white) !important;
      }

      span::before {
        top: -7px;
      }

      span::after {
        bottom: -7px;
      }
    }

    &__logo {
      display: flex;
      flex-basis: 40%;
      justify-content: center;
      height: 3.5rem;
      user-select: none;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &__options {
      display: flex;
      flex-basis: 30%;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
    }

    &__option {
      cursor: pointer;

      &:not(:last-child) {
        margin-right: 1.5rem;
      }

      .avatar {
        @include round-image(2.9rem, cover);
        border: 1px solid color(white);
        user-select: none;
      }
    }

    &__menu {
      position: absolute;
      left: -35rem;
      display: flex;
      flex-direction: column;
      margin: 0;
      padding: 0 1rem;
      width: 30rem;
      height: 96vh;
      overflow: auto;
      background: theme(primary);
      transition: left 0.3s;

      &.active {
        left: 0;
      }

      &-profile {
        display: flex;
        align-items: center;
        border-bottom: 1px solid color(white);
        padding: 10px;

        .avatar {
          @include round-image(5rem, contain);
          margin-right: 20px;
        }

        .user-details {
          @include font-size(small);
          padding-top: 10px;
          text-transform: uppercase;
          color: color(white);

          h3 {
            margin: 0;
            text-transform: capitalize;
          }
        }
      }

      &-list {
        margin-top: 20px;

        .navbar-list {
          margin-top: 10px;
          padding-left: 5rem;
          max-height: 0;
          overflow: hidden;
          transition: 0.8s max-height;

          &__item {
            margin: 10px 0;
            list-style: none;
          }

          &__link {
            @include font-size(regular);
            display: block;
            padding: 0 1rem;
            text-transform: capitalize;
            font-weight: 300;
            color: color(white);

            &:hover,
            &.active {
              text-decoration: none;
              color: color(white);
            }
          }
        }
      }

      &-legal {
        display: flex;
        justify-content: space-between;
        margin-top: auto;
        border-top: 1px solid color(white);
        padding: 10px;
        text-transform: uppercase;
        color: color(white);
      }

      &__item {
        position: relative;
        list-style: none;

        .settings-icon {
          position: absolute;
          right: 2rem;
          transition: 0.3s transform;
        }
      }

      &__link {
        @include font-size(medium);
        display: block;
        padding: 1rem;
        text-transform: capitalize;
        font-weight: 300;
        color: color(white);
        transition: text-decoration 0.2s, color 0.2s;

        svg {
          margin-right: 0.5rem;
          height: 30px;
        }

        &:hover,
        &.active {
          border-radius: 4px;
          background: theme(secondary);
          opacity: 0.85;
          text-decoration: none;
          color: color(white);
        }
      }
    }
  }
}
