// @import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,600,700,900');

// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------

// CircularStd

@font-face {
  font-family: 'Inter';
  font-weight: 400;
  font-style: normal;
  src: local('Inter'), url('../../fonts/Inter/static/Inter-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 500;
  font-style: normal;
  src: local('Inter'), url('../../fonts/Inter/static/Inter-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 600;
  font-style: normal;
  src: local('Inter'), url('../../fonts/Inter/static/Inter-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 800;
  font-style: normal;
  src: local('Inter'), url('../../fonts/Inter/static/Inter-ExtraBold.ttf') format('truetype');
}


@font-face {
  font-family: 'Circula-Std';
  font-weight: normal;
  font-style: normal;
  src: url('../../fonts/CircularStd/CircularStd-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Circula-Std';
  font-weight: 800;
  font-style: normal;
  src: url('../../fonts/CircularStd/CircularStd-SemiBold.otf') format('opentype');
}

// iconmoon
@font-face {
  font-family: 'icomoon';
  font-weight: normal;
  font-style: normal;
  src: url('../../fonts/icomoon/icomoon.eot?xxewj5#iefix') format('embedded-opentype'),
  url('../../fonts/icomoon/icomoon.ttf?xxewj5') format('truetype'),
  url('../../fonts/icomoon/icomoon.woff?xxewj5') format('woff'),
  url('../../fonts/icomoon/icomoon.svg?xxewj5#icomoon') format('svg');
  font-display: block;
}


