.modal {
  @include respond('fm') {
    .modal-lg,
    .modal-xl {
      max-width: 700px;
    }

    .modal-lg,
    .modal-xxl {
      max-width: 870px;
    }

    .modal-lg,
    .modal-xxxl {
      max-width: 920px;
    }
  }

  &-backdrop {
    &.show {
      opacity: 0.9;
    }
  }

  &__body,
  &__footer,
  &__header {
    padding: 1.5rem 2.5rem;
  }

  &__body {
    padding: 2.4rem 2.5rem;
  }

  &__btn {
    border-radius: 8px;
    border: 0 !important;
    min-width: 14rem;
    height: 5rem;
    font-size: 1.6rem;
    transition: transform 0.3s linear;

    &:hover {
      transform: translateY(-3px);
    }

    &:disabled {
      cursor: not-allowed;
    }

    &:active {
      box-shadow: none !important;
      transform: translateY(0);
    }

    &:focus {
      box-shadow: none !important;
    }

    &--decline {
      &,
      &:hover,
      &:focus,
      &:active {
        background: rgba(theme(secondary), 0.15) !important;
        color: theme(secondary) !important;
      }
    }

    &--accept {
      &,
      &:hover,
      &:focus,
      &:active {
        background: rgba(color(cyan), 0.15) !important;
        color: color(cyan) !important;
      }
    }

    &--assign {
      &,
      &:hover,
      &:focus,
      &:active {
        background: rgba(color(grey), 0.15) !important;
        color: color(grey) !important;
      }
    }

    &--start {
      &,
      &:hover,
      &:focus,
      &:active {
        background: theme(primary) !important;
        color: color(white) !important;
      }
    }

    &--proceed {
      &,
      &:hover,
      &:focus,
      &:active {
        background: color(yellow) !important;
        color: color(white) !important;
      }
    }

    &--complete {
      &,
      &:hover,
      &:focus,
      &:active {
        background: rgba(color(yellow), 0.15) !important;
        color: color(yellow) !important;
      }
    }

    &--dispatch {
      &,
      &:hover,
      &:focus,
      &:active {
        background: rgba(color(green), 0.15) !important;
        color: color(green) !important;
      }
    }
  }

  &--confirm {
    border-radius: 8px !important;

    .modal {
      &__body {
        padding: 0;
      }

      &__title {
        margin-bottom: 3rem;
        text-align: center;
        font-size: 2rem;
        font-weight: 600;
        color: color(dark);

        &.with-subtitle {
          margin-bottom: 1rem;
        }
      }

      &__subtitle {
        margin-top: 4rem;
        margin-bottom: 3rem;
        text-align: center;
        font-size: 1.4rem;
        color: color(dark);
      }

      &__spinner {
        margin-top: -20px;

        .loader-block {
          min-height: 6rem;
        }
      }
    }

    &__body {
      @include respond('xs') {
        padding: 2rem;
      }
      padding: 3.5rem 3rem 2rem;
    }

    &__footer {
      display: flex;
      justify-content: center;
      padding-bottom: 5rem;
    }

    &__btn {
      margin: 0 1rem;
      box-shadow: none;
      border-radius: 4px;
      border: 1px solid var(--primary);
      padding: 0.5rem 3rem;
      background: var(--primary) !important;
      text-align: center;
      font-size: 1.4rem;
      color: color(white);

      &:not(:first-child) {
        border: 1px solid var(--secondary);
        background: color(white) !important;
        color: var(--secondary);
      }

      &:focus {
        box-shadow: none;
      }

      &.bold {
        font-weight: 600;
      }

      &.is-loading {
        position: relative;
        pointer-events: none;
        color: transparent !important;

        &::after {
          position: absolute !important;
          top: calc(50% - (1.4em / 2));
          left: calc(50% - (1.4em / 2));
          display: block;
          border-radius: 50%;
          border: 2px solid color(grey);
          border-top-color: transparent !important;
          border-right-color: transparent !important;
          width: 1.4em;
          height: 1.4em;
          animation: rotate 500ms infinite linear;
          transform-origin: center;
          content: '';
        }
      }

      &:hover {
        background: rgba(color(grey), 0.1) !important;
      }
    }
  }

  &-content {
    border-radius: 8px;
    border: 0;
    background-color: color(white);

    &.preview {
      background: transparent;
    }

    &.blue {
      color: color(cyan);

      .card__user .checker::after {
        background: rgba(color(cyan), 0.3);
        color: color(cyan);
      }
    }

    &.purple {
      color: theme(primary);

      .card__user .checker::after {
        background: rgba(theme(primary), 0.3);
        color: theme(primary);
      }
    }

    &.yellow {
      color: color(yellow);

      .card__user .checker::after {
        background: rgba(color(yellow), 0.3);
        color: color(yellow);
      }

      .modal__header--icon .comment {
        svg {
          path {
            fill: color(yellow);
          }
        }
      }
    }

    &.green {
      color: color(green);

      .card__user .checker::after {
        background: rgba(color(green), 0.3);
        color: color(green);
      }

      .modal__header--icon .comment {
        svg {
          path {
            fill: color(green);
          }
        }
      }
    }

    &.grey {
      color: color(grey);

      .card__user .checker::after {
        background: rgba(color(grey), 0.3);
        color: color(grey);
      }

      .modal__header--icon .comment {
        svg {
          path {
            fill: color(grey);
          }
        }
      }
    }
  }

  &__day-picker {
    .DayPickerInput {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      border-radius: 8px;
      border: 1px solid rgba(151, 151, 151, 0.1);
      padding-right: 2rem;
      padding-left: 2rem;
      height: 5rem;
      background-color: color(white);

      input {
        border: 0;
        width: 100%;
        background: color(white);

        &:focus {
          outline: none;
        }
      }
    }

    .DayPicker {
      font-size: 1.4rem;
    }

    .DayPickerInput-OverlayWrapper {
      position: absolute;
      top: 100%;
      left: 0;
    }
  }

  &__footer {
    @include flex-display(row);

    .btn {
      &:not(:last-child) {
        margin-right: 2rem;
      }
    }
  }

  &__header {
    border-bottom: 1px solid rgba(67, 102, 118, 0.15);

    &--icon {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      margin-top: 10px;

      .comment {
        @include flex-display(column);
        margin-right: 1rem;
        cursor: pointer;

        span {
          text-transform: uppercase;
          font-size: 1.2rem;
          color: color(grey);
        }
      }
    }

    &--inner {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }

    &__subtitle {
      margin-bottom: 0;
      font-size: 1.6rem;
      color: color(grey);
    }

    &__title {
      margin-top: 10px;
      margin-bottom: 5px;
      font-size: 2rem;
      font-weight: 600;
      color: inherit;

      span {
        @include font-size(m-large);
        display: inline-block;
        padding-bottom: 10px;
        vertical-align: middle;
      }
    }

    &--toggle {
      margin-right: 3rem;
    }
  }

  &__list {
    &__item {
      margin-bottom: 2rem;
    }

    &__text {
      margin-bottom: 0;

      &--1 {
        margin-bottom: 5px;
        text-transform: capitalize;
        font-size: 1.2rem;
        color: color(grey);
      }

      &--2 {
        text-transform: capitalize;
        font-size: 1.4rem;
        font-weight: 600;
        color: color(dark);

        span {
          display: block;
        }
      }

      &--3 {
        cursor: pointer;
        text-transform: none;
        font-size: 1.2rem;
        color: inherit;
      }

      &--4 {
        margin-top: -1rem;
        text-transform: none;
        font-size: 1.4rem;
        font-style: italic;
        color: lighten(color(watermelon), 10%);
      }

      &--pill {
        margin-bottom: 5px;
        text-transform: capitalize;
        font-size: 1.2rem;
        color: color(grey);
        border-radius: 30px;
        padding: 0 0.5rem;
        background: rgba(106, 105, 228, 0.2);
        display: inline !important;
      }
    }
  }

  &-search {
    @include respond('xs') {
      padding-right: 1rem;
      padding-left: 1rem;
    }

    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
    border-radius: 8px;
    border: 1px solid rgba(151, 151, 151, 0.1);
    padding: 1rem 2rem;
    width: 100%;
    height: 5rem;
    background-color: rgba(68, 157, 249, 0.03);

    &__bar {
      border: 0;
      width: 100%;
      background: transparent;
      font-size: 1.6rem;
      font-weight: 400;
      color: color(dark);

      &:focus {
        outline: none;
      }

      &::placeholder {
        color: rgba(color(dark), 0.5);
      }
    }
  }

  &-select {
    &__indicator-separator {
      display: none;
    }

    &__control {
      @include respond('xs') {
        padding-right: 0;
        padding-left: 0;
      }

      padding-right: 1rem;
      padding-left: 1rem;
      height: 5rem;

      &--is-focused {
        &,
        &:hover {
          box-shadow: 0 0 2px rgba(151, 151, 151, 0.3) !important;
          border: 1px solid rgba(151, 151, 151, 0.1) !important;
        }
      }
    }

    &__option {
      color: color(dark) !important;

      &--is-selected {
        background-color: color(purple) !important;
        color: color(white) !important;
      }
    }
  }

  &__task {
    &-img {
      margin-bottom: 2rem;
      cursor: pointer;

      svg {
        margin-left: 10px;
        width: 50px;
        height: 50px;
      }
    }
  }

  &-reason {
    &.modal-dialog {
      @include respond('fm') {
        max-width: 500px;
      }
    }

    .modal-content {
      border-radius: 8px;
    }

    .modal__body {
      padding-top: 0;
    }

    .modal__header {
      border-bottom: 0;
      padding-bottom: 0;

      &--text {
        width: 100%;
        text-align: center;

        h3 {
          @include font-weight(medium);
          margin-top: 18px;
          margin-bottom: 28px;
        }

        h4 {
          margin-bottom: 39px;
          color: color(dark);
        }
      }
    }

    textarea {
      height: 130px;
    }
  }
}

.backdrop-style {
  background: rgba(0, 0, 0, 0.5);
}
