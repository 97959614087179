.patients-context-menu {
  z-index: 5;
  position: absolute;
  right: 0;
  margin-top: 1rem;
  min-width: 17.5rem;
  animation: add-physician-note .4s ease-in-out forwards;

  &__body {
    box-shadow: 0 4px 20px rgba(21, 21, 25, 0.08);
    border-radius: 4px;
    padding: 1rem;
    background: color(white);
    list-style-type: none;
  }

  &__content {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }

  &__text {
    margin-bottom: 0.5rem;
    cursor: pointer;
    text-transform: capitalize;
    line-height: 25px;
    font-size: 14px;
    color: color(dark);
    transition: 0.3s linear color;

    &:hover {
      color: color(grey);
    }
  }
}

.patients-checker {
  box-sizing: border-box;
  margin-right: 1rem;
  margin-bottom: 1rem;
  border-radius: 4px;
  border: 2px solid rgba(67, 102, 118, 0.148353);
  padding: 0.5rem 1.5rem;
  text-align: center;
  transition: 300ms linear border;

  &.checked {
    border-color: theme(primary);
  }
}
