.digit-selector {
  flex-direction: column;
  margin-bottom: 3rem;

  &-wrapper {
    display: flex;
    border: 1.5px solid rgba(67, 102, 118, 0.148353);
    padding: 4px 20px 12px;
    border-radius: 4px;

    &--grey {
      background-color: rgba($color: color(grey), $alpha: 0.1);
    }
  }

  &-input {
    display: flex;
    flex-direction: column;
    margin-right: 6px;

    &__text {
      margin-bottom: 9px;
      font-size: 1.2rem;
      letter-spacing: 0.5px;

    }

    &__field {
      box-shadow: none;
      border: 0;
      padding: 0;
      text-align: left;
      width: 100%;
      background: transparent;
      letter-spacing: 0.5px;
      font-size: 1.4rem;
      font-weight: 400;
      color: color(dark);

      &::placeholder {
        color: rgba(color(dark), 0.4);
      }

      &:focus,
      &:active {
        outline: none;
        box-shadow: none;
        border: 0;
      }
    }

    &__control {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
  }
}

.show-more {
  display: flex;
  width: 100%;

  &-bar {
    background: rgba(67, 102, 118, 0.148353);
    height: 2px;
    width: 85%;
    margin-top: 11px;
  }
}
