.patient-summary {
  box-shadow: 0 2px 4px rgba(106, 105, 228, 0.03);
  border-radius: 7px;
  padding: 1.75rem 3rem;
  background: color(white);

  &__user-info,
  &__provider-info {
    flex: 1;
  }

  &__user-info {
    @include flex-display(row);
    align-items: flex-start;
    justify-content: flex-start;
    border-right: 2px solid color(light-grey);
    padding: 1rem 0;

    .avatar {
      margin-right: 3rem;
      border-radius: 50%;
      width: 95px;
      height: 95px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .title {
      margin-bottom: 1.8rem;
      line-height: 25px;
      font-size: 20px;
      font-weight: 500;
    }

    .provider {
      margin-top: 0.75rem;
      line-height: 20px;
    }

    .provider-id {
      margin: 0 1rem;
      border-radius: 4px;
      padding: 0.75rem 1.5rem;
      background: rgba(106, 105, 228, 0.15);
      line-height: 18px;
      font-size: 13px;
    }
  }

  &__provider-info {
    justify-content: space-between;

    .main-info {
      @include flex-display(row);
      @include inherit-link();
      justify-content: space-between;
      padding-left: 7rem;

      p {
        line-height: 20px;
      }

      &__right {
        .access-log-btn {
          box-shadow: 0 2px 4px rgba(106, 105, 228, 0.03);
          border-radius: 7px;
          border: 2px solid rgba(67, 102, 118, 0.148353);
          padding: 1rem 2.5rem;
          background: color(white);
          color: color(grey);
        }

        .transfer-patient__consultant {
          @include flex-display(row);
          justify-content: space-between;
          padding: 1rem 1.5rem;
        }
      }
    }
  }
}
