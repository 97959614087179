.system-configuration {
  position: relative;
  display: flex;
  flex-direction: column;

  &__setup {
    position: relative;
    margin: 10px auto 30px;
    max-width: 1025px;

    .toggle-group {
      display: flex;
      justify-content: space-between;
      margin: 10px 0;
      width: 20rem;

      &__text {
        margin-top: 0.3rem;
        margin-right: 5rem;
        text-transform: capitalize;
      }
    }

    .form-tags {
      position: relative;

      &__svg {
        position: absolute;
        top: 1rem;
        right: 2rem;
        border-left: 1px solid color(grey);
        padding: 5px 10px;
        background: color(white) !important;
        pointer-events: none;
      }

      &__unit {
        position: absolute;
        top: 0;
        right: 1.5rem;
        width: 15rem;
        height: 6.3rem;

        .time-unit {
          @include font-size(medium);
          @include font-weight(regular);
          border-radius: 4px;
          padding: 0.5rem 1.5rem;
          height: 100%;
          background: rgba(106, 105, 228, 0.28);

          p {
            @include abs-center();
            text-align: center;
            text-transform: capitalize;
            color: color(dark);
            user-select: none;
          }
        }
      }
    }
  }

  &__config-setup {
    flex: 1 0 auto;
    padding-top: 2.5rem;
    min-height: 65vh;
  }

  &__page-controls {
    @include respond('xl') {
      width: 1025px;
    }

    flex-shrink: 0;
    margin: 10px auto;
    padding: 0;
    width: 100%;
  }

  .table {
    &-body {
      td:nth-child(2) {
        text-transform: uppercase;
      }
    }
  }
  @include respond('as') {
    .table {
      th:nth-child(3),
      td:nth-child(3) {
        display: none;
      }
    }
  }
}
