.task-container {
  $this: &;
  display: flex;
  flex: 1;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid rgba(67, 102, 118, 0.15);
  padding: 2rem;
  background-color: color(white);

  &.blue {
    color: color(cyan);
  }

  &.purple {
    color: theme(primary);

    .card__task__button {
      background: rgba(theme(primary), 0.1);
      color: theme(primary);
    }
  }

  &.yellow {
    color: color(yellow);

    .card__task__button {
      background: rgba(color(yellow), 0.1);
      color: color(yellow);
    }
  }

  &.green {
    color: color(green);

    .card__task__button {
      background: rgba(color(green), 0.1);
      color: color(green);
    }
  }

  &.grey {
    color: color(grey);

    .card__task__button {
      background: rgba(color(grey), 0.1);
      color: color(grey);
    }
  }

  &__body {
    flex: 1;
    padding: 3px;
    width: 100%;
    height: 5rem;
    overflow: auto;

    &.empty {
      @include flex-display(column);

      .empty-text {
        margin-top: 1rem;
        margin-bottom: 0;
        font-size: 1.8rem;
        font-weight: 500;
        color: color(grey);
      }
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.5rem;
    width: 100%;

    &--text {
      flex: 1;
    }

    &--select {
      header {
        @include font-size(small);
        box-shadow: 0 0 3px rgba(color(black), 0.2);
        padding: 0.3rem 2.5rem;
        cursor: pointer;
      }

      main {
        z-index: 2;
        position: absolute;
        top: 2.9rem;
        right: 0.5rem;
        box-shadow: 0 0 2px rgba(151, 151, 151, 0.3) !important;
        padding: 5px;
        background: color(white);

        p {
          @include font-size(small);
        }
      }
    }
  }

  &__items {
    margin-bottom: 0.5rem;
    font-size: 1.2rem;
    font-weight: 500;
    color: color(grey);
  }

  &__title {
    font-size: 2rem;
    font-weight: 600;
    color: color(dark);
  }
}
