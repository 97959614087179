// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------
.btn {
  border-radius: 0;
  padding: 0.5rem 3rem;
  height: 4.5rem;
  font-size: 1.4rem;
  font-weight: normal;

  a {
    text-decoration: none;
    color: inherit !important;
  }

  &:active,
  &:hover,
  &:focus {
    outline: none;
    box-shadow: none;
  }

  &__fw {
    width: 100%;
  }

  &__icon {
    margin-right: 1.5rem;
  }

  &__primary {
    border-radius: 4px;
    border: transparent;
    min-width: 10rem;
    background: theme(secondary);
    letter-spacing: 1px;
    font-size: 1.4rem;
    color: color(white);
    transition: transform 0.2s linear, opacity 0.2s linear;

    &:hover {
      background-color: theme(secondary) !important;
      opacity: 0.85;
      transform: translateY(-3px);
    }

    &--outline {
      display: block;
      margin-top: 1.5rem;
      text-align: center;
      letter-spacing: 1px;
      font-size: 1.4rem;
      color: theme(secondary);

      &:hover {
        text-decoration: none;
        color: rgba(theme(secondary), 0.7);
      }

      &-border {
        border-radius: 4px;
        border: 1px solid rgba(67, 102, 118, 0.15);

        &:hover {
          background: theme(primary);
          color: color(white) !important;
        }
      }
    }
  }

  &__secondary {
    border-radius: 4px;
    border: transparent;
    min-width: 10rem;
    background-color: theme(primary);
    letter-spacing: 1px;
    font-size: 1.4rem;
    color: color(white);
    transition: transform 0.2s linear, opacity 0.2s linear, background-color 0.2s linear;

    &:active {
      box-shadow: none !important;
      background-color: inherit !important;
      color: inherit !important;
      transform: translateY(0);
    }

    &:hover {
      box-shadow: 0 0 2px rgba(color(black), 0.1);
      background-color: theme(primary) !important;
      opacity: 0.85;
      transform: translateY(-3px);
    }

    &:disabled {
      &:hover {
        background-color: color(grey) !important;
        color: color(white) !important;
      }
    }

    &--outline {
      border: 1px solid theme(primary);
      background: transparent;
      color: theme(primary);

      &:hover {
        color: color(white) !important;
      }
    }
  }

  &__switch {
    border: 1px solid theme(primary) !important;
    padding: 5px 20px;
    min-width: auto;
    background-color: transparent !important;
    color: theme(primary) !important;

    &:hover {
      background-color: theme(primary) !important;
      color: color(white) !important;
      transform: none;
      opacity: 0.5;
    }

    &--active {
      background-color: theme(primary) !important;
      color: color(white) !important;
    }
  }

  &__utility {
    border-radius: 4px;
    border: 1px solid theme(primary);
    min-width: 10rem;
    background-color: color(white);
    letter-spacing: 1px;
    font-size: 1.4rem;
    color: theme(primary);
    transition: transform 0.2s linear, background-color 0.2s linear;

    &:active {
      box-shadow: none !important;
      background-color: inherit !important;
      color: theme(primary) !important;
      transform: translateY(0);
    }

    &:hover {
      box-shadow: 0 0 2px rgba(theme(primary), 0.1);
      border: 1px solid theme(primary);
      background-color: darken(color(white), 0.3);
      color: theme(primary);
      transform: translateY(-3px);
    }

    &-border {
      border-radius: 4px;
      border: 1px solid rgba(67, 102, 118, 0.15);
      background-color: color(white);
      color: color(dark);

      &:hover {
        border: 1px solid theme(primary);
        background: theme(primary);
        color: color(white);
      }

      &:disabled {
        border: 1px solid darken(color(white), 20%);
        background-color: color(white);
        color: color(grey) !important;

        &:hover {
          border: 1px solid theme(primary);
          background-color: theme(primary);
          color: color(white) !important;
        }
      }
    }
  }

  &__onboarding {
    min-width: 128px;
  }

  &__grey-outline {
    border-color: rgba(67, 102, 118, 0.1484);
    color: rgba(74, 74, 74, 0.72);
  }

  &__cancel {
    border-color: color(watermelon);
    color: color(watermelon);

    &:hover {
      border-color: color(watermelon);
      color: color(watermelon);
    }
  }

  &__cancel--filled {
    border: none;
    background-color: color(watermelon);
    color: white;

    &:hover {
      border-color: color(watermelon);
      color: color(watermelon);
    }
  }

  &__loading {
    position: relative;
    pointer-events: none;
    color: transparent !important;

    &::after {
      position: absolute !important;
      top: calc(50% - (1.4em / 2));
      left: calc(50% - (1.4em / 2));
      display: block;
      border-radius: 50%;
      border: 2px solid #fff;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      width: 1.4em;
      height: 1.4em;
      animation: rotate 500ms infinite linear;
      transform-origin: center;
      content: '';
    }
  }

  &__loading--alt {
    &::after {
      border: 2px solid #6a69e4;
    }
  }

  &__discard {
    border-radius: 4px;
    border: 1px solid color(watermelon);
    background: color(white);
    color: color(watermelon);
  }

  &__support {
    border-radius: 4px;
    border: none;
    background: color(grey-white);
    color: color(black);

    &:hover {
      box-shadow: 0 0 2px rgba(color(black), 0.1);
      background-color: theme(primary) !important;
      opacity: 0.85;
      transform: translateY(-3px);
    }
  }

  &--nowrap {
    white-space: nowrap;
  }

  &__with-dropdown {
    position: relative;
  }

  &__dropdown-items {
    position: absolute;
    top: 110%;
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(21, 21, 25, 0.08);
    border-radius: 4px;
    min-width: 334px;
    padding: 17px 23px;

    & > * {
      padding: 2px 7px;
      cursor: pointer;
    }

    &--left {
      left: 0%;
    }

    &--right {
      right: 0%;
    }
  }
}

.section-button {
  border-radius: 8px;
  border: transparent;
  padding: 10px 15px;
  background: color(white);
  text-transform: capitalize;
  font-size: 1.4rem;
  font-weight: normal;
  color: color(grey);

  &:active,
  &:focus,
  &__active {
    outline: none !important;
    box-shadow: none !important;
    background-color: color(off-purple) !important;
    color: color(purple) !important;
  }
}

.signed-by__btn {
  border-radius: 8px;
  padding: 5px 15px;
  height: auto;
  border: 1px solid theme(secondary) !important;
  background: transparent;
  text-transform: capitalize;
  font-size: 1.4rem;
  font-weight: normal;
  color: theme(secondary) !important;

  &:hover {
    background: transparent;
    color: theme(secondary) !important;
  }

  &:active,
  &:focus,
  &__active {
    outline: none !important;
    box-shadow: none !important;
    background: transparent;
    border: 1px solid theme(secondary) !important;
  }
}

.delete-button {
  @extend .btn;
  border: transparent;
  padding: 5px 0;
  width: auto !important;
  height: auto !important;
  background: color(white);
  text-transform: capitalize;
  font-size: 1.4rem;
  font-weight: normal;
  color: color(watermelon);

  &:hover,
  &:focus {
    background-color: color(white) !important;
    opacity: 0.85;
    color: color(watermelon) !important;
  }

  &__loading {
    position: relative;
    border-radius: 5px;
    border: 1px solid color(watermelon) !important;
    pointer-events: none;
    color: transparent !important;

    &:focus {
      outline: none;
      background-color: color(white) !important;
      color: transparent !important;
    }

    &::after {
      position: absolute !important;
      top: calc(50% - (1.4em / 2));
      left: calc(50% - (1.4em / 2));
      display: block;
      border-radius: 50%;
      border: 2px solid color(watermelon);
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      width: 1.4em;
      height: 1.4em;
      animation: rotate 500ms infinite linear;
      transform-origin: center;
      content: '';
    }
  }
}

.number__select-box {
  width: 52px;
  height: 35px;
  border: 2px solid rgba(67, 102, 118, 0.148353);
  border-radius: 4px;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  transition: 0.3s all ease-out;

  &.active {
    background-color: var(--primary-color);
    color: #fff;
  }
}

.btn_responsive {
  min-height: 4.5rem;
  height: auto;
  width: auto;
}
