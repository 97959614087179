.upload-file {
  display: flex;
  align-items: center;
  border-radius: 0.8rem;
  padding: 0 1rem;
  min-height: 7rem;
  background-color: rgba(68, 157, 249, 0.05);
  cursor: pointer;

  &__block {
    height: calc(100% - 3rem);
  }

  &__list {
    margin-top: 1rem;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
  }

  span {
    margin-bottom: 0;
    margin-left: 1rem;
    font-size: 1.4rem;
    color: color(grey);
    user-select: none;
  }

  input {
    display: none;
  }
}

.upload-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 3rem;
  border-radius: 1rem;
  border: 1.5px dashed theme(primary);
  padding: 1rem;
  width: 100%;
  min-height: 105px;
  background: rgba(106, 105, 228, 0.22);

  &__camera {
    @include flex-display(row);
    border-radius: 50%;
    border: 1px solid theme(primary);
    width: 70px;
    height: 70px;
  }

  &__file {
    display: flex;

    .remove-icon {
      margin-left: 3.4rem;
      border-radius: 4px;
      width: 2.2rem;
      height: 2.2rem;
      background: color(light-watermelon);
      cursor: pointer;
      text-align: center;
      color: color(watermelon);
    }
  }

  input {
    display: none;
  }

  img {
    max-width: 100%;
    max-height: 70px;
  }
}

.delete-file {
  cursor: pointer;
  color: theme(secondary) !important;
}
