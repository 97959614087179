.webcam-container {
    position: relative;

    .cancel-control {
        position: absolute;
        top: 3rem;
        right: 3rem;
        cursor: pointer;

        i {
            font-size: 24px;
            color: color(white);
        }
    }

    &__controls {
        @include flex-display(row);
        position: absolute;
        right: 0;
        bottom: 3rem;
        left: 0;

        .capture-control {
            @include round-image(50px, cover);
            @include flex-display(row);
            border: 4px solid color(white);
            padding: 2px;

            &__inner {
                @include round-image(100%, cover);
                background-color: color(white);
            }
        }
    }
}
